/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Subtitle, Image } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-ei2l74 bg--top --full" name={"einleitung"} fullscreen={true} css={css`
        
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 pt--10 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4" anim={"2"} animS={"4"} style={{"maxWidth":928}}>
              
              <Title className="title-box fs--86 title-box--invert lh--12" content={"Die richtige tschechische Bäckerei mit Geschichte & Tradition"}>
              </Title>

              <Button className="btn-box btn-box--hvr2" href={"#sluzby"} content={"unseres Gebäck"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"einleitung-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 pb--40 pt--50" anim={"2"} animS={"4"} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4" anim={"2"} animS={"4"}>
              
              <Title className="title-box" style={{"maxWidth":872}} content={"Wir backen traditionelles tschechisches Gebäck nach unsere Rezeptur und aus bestimmten Nahrungsmitteln — von Brötchen zu Bretzeln"}>
              </Title>

              <Button className="btn-box btn-box--hvr2" href={"#sluzby"} content={"Auf die Bestellung"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-qde8ls --border2 js-anim  --anim6 --anim-s4 bg--center --full --parallax pb--60 pt--60" anim={"6"} name={"bild"} animS={"4"} border={"2"} parallax={true} fullscreen={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"presliste"}>
          
          <ColumnWrap className="column__flex --center el--3 pb--50 pt--40 flex--top" style={{"maxWidth":1150}} columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box lh--1" content={"unseres Gebäck"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

              <Text className="text-box mt--60" style={{"maxWidth":254}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

              <Text className="text-box mt--60" style={{"maxWidth":254}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"fotogallerie"}>
          
          <ColumnWrap className="column__flex --center el--2 pb--0 pl--06 pr--06 flex--top" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4 --right" anim={"2"} animS={"4"} border={null}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/96/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/96/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/96/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/96/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/96/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/96/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box pl--0 pr--0" content={"Mehrkornbrot 500g  —  1.89 €"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 --left mt--80 pt--40" anim={"2"} animS={"4"}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/96/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/96/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/96/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/96/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/96/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/96/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box pl--0 pr--0" content={"Mehrkornbrot 500g  —  1.89 €"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--80" name={"besuchen-sie-uns"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 pb--80 pt--80" anim={"2"} animS={"4"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62 lh--1" style={{"maxWidth":872}} content={"Aus dem Backofen."}>
              </Title>

              <Button className="btn-box" href={"/de/"} content={"Besuchen Sie uns"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pl--40 pr--40 pt--40" name={"partei"}>
          
          <ColumnWrap className="column__flex el--3 --full pl--20 pr--20 flex--bottom" style={{"maxWidth":""}} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Backerei Straßenname<br>Oeder Weg 22<br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":254}} content={"+49 797 811 2X05<br>info@vase-stranky.com<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Wir freuen uns auf Ihren Besuch!"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Mo—Fr<br>7:00—15:00<br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":254}} content={"Sa—So<br>7:00—15:00<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}